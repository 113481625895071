import { createStore } from "vuex";
import VuexPersist from "vuex-persist";
import axios from 'axios';
const vuexLocalStorage = new VuexPersist({
key: "sesec", // The key to store the state on in the storage provider.
storage: window.localStorage, // or window.sessionStorage or localForage
// Function that passes the state and returns the state with only the objects you want to store.
// reducer: state => state,
// Function that passes a mutation and lets you decide if it should update the state in localStorage.
// filter: mutation => (true)
});

const base_url = import.meta.env.VITE_API_SESEC_SERVICE

export const userstore = createStore({
  plugins: [vuexLocalStorage.plugin],
  state() {
    return {
    action: "test", 
	answers: {},
	answerlist: {},
	allanswers: [],
	calendar: [],
	cat: "xxx",
	catalog: [],
	catlabel: "",
	cats: [],
	catsinfos: [],
	result: "",
	dsgvo: false,
	errors: [],
	fetching: false,
	group: "",
	info: [],
	metadata: { data: {
	    Antworten: {},
	    Frage: "",
	    Optionen: [],
	}},
	metafinished: false,
	num: 0,
	query: {},
	registered: false, // one quiz finished == true
	selectedCat: false,
	userstats: [],
	sumcat: [
	    { results: "korrekt", points: 0 },
            { results: "falsch", points: 0 },
	],
	userid: "aaa",
    };
  },
  getters: {
      answerlist: (state) => state.answerlist,
      answers: (state) => state.answers,
      allanswers: (state) => state.allanswers,
      result: (state) => state.result,
      calendar: (state) => state.calendar,
      cat: (state) => state.cat,
      catalog: (state) => state.catalog,
      catlabel: (state) => state.catlabel,
      cats: (state) => state.cats,
      dsgvo: (state) => state.dsgvo,
      group: (state) => state.group,
      errors: (state) => state.errors,
      evaluation: (state) => state.evaluation,
      fetching: (state) => state.fetching,
      metadata: (state) => state.metadata,
      metafinished: (state) => state.metafinished,
      num: (state) => state.num,
      query: (state) => state.query,
      selectedCat: (state) => state.selectedCat,
      sumcat: (state) => state.sumcat,
      tester: (state) => state.tester,
      userid: (state) => state.userid,
      useridType: (state) => state.useridType,
      action: (state) => state.action,
      info: (state) => state.info,
      catsinfos: (state) => state.catsinfos,
      lineData: (state) => state.lineData,
      registered: (state) => state.registered,
      userstats: (state) => state.userstats,
  },
    mutations: {
	evaluate(state) {
	    const antworten = JSON.parse(JSON.stringify(this.state.catalog[this.state.num].answers));
	    const querynumber = JSON.parse(JSON.stringify(this.state.catalog[this.state.num].id));
	    const index_aus_frageliste = this.state.num;
	    const given_id = this.state.answers[querynumber]
	    const correct_id = this.state.answerlist[querynumber]
	    let icon = ""
	    given_id === correct_id ? icon = "korrekt": given_id == "99"? icon="fehlt": icon="falsch";
	    this.state.result = icon;
    },
    sumPoints(state) {
	state.sumcat[0].points = 0;
	state.sumcat[1].points = 0;
	for (const key in state.answerlist) {
            if ( state.answerlist[key] === state.answers[key] ) {
		state.sumcat[0].points++;
            } 
            else {
		state.sumcat[1].points++;
	    }
	}
    },
    checkForm(state) {
	if (state.cat && state.dsgvo) {
            return true;
	} else {
            return false;
	}
    },
    next(state) {
	if (state.num < state.catalog.length) {
            state.num++;
	}
	this.state.query = state.catalog[state.num];
	this.commit("evaluate");
    },
    nextAction(state, payload) {
	state.num = -1;
	state.query = state.catalog[0];
	this.commit("next");
	state.action = payload.action;
    },
    setCat(state, payload) {
	  state.sumcat[0].points = 0;
	  state.sumcat[1].points = 0;
	  state.answers = {};
	  state.cat = payload.category; 
	  state.action = "test";
	this.dispatch("fill");
	this.dispatch('getCorrectAnswers', state);
    },
    setUserID(state, payload) {
	state.userid = payload.uid;
	state.cats = [];
	state.answers = {};
	state.allanswers = [];
	state.info = [];
    },
      setGroupID(state, payload) {
	  state.group = payload.group;
	  this.dispatch("setGroup");
    },
      setDSGVO(state, payload) {
	state.dsgvo = payload.dsgvo;
    },
	saveAnswer(state, payload) {
	state.answers[payload.num] = payload.idx;
    },

    fillCatalog(state, result) {
	state.catalog = result;
    },

    fillCats(state, result) {
	state.cats = result;
    },
    fillCorrectAnswers(state, result) {
	state.answerlist = result;
    },
    getQueries(state, result) {
	state.cats = result;
    },    
    setCalendar(state, result) {
	state.calendar = result;
    },
      setMetaFinish(state) {
	state.metafinished = true;
    },
    setNum(state, result) {
	state.num = result.query;
    },
    setMeta(state, result) {
	state.metadata = result;
    },
    setInfo(state, result) {
	state.info = result;
    },
    setAllAnswers(state, result) {
	state.allanswers = result;
    },
    setUserStats(state, result) {
	state.userstats = result;
    },
    setCatsInfos(state, result) {
	state.catsinfos = result;
    },
    setRegistered(state, result) {
	state.registered = result;
    },   
  },

    
  actions: {
    async fill({ commit, state }) {
      /* Querylist for one catalog */
      state.fetching = true;
	const response = await fetch(base_url+"/queries/"+ state.cat,
				     { crossdomain: true ,
				       refererPolicy: 'no-referrer-when-downgrade'});
      try {
          const result = await response.json();

	const res = result["querylist"]
	  if(res.length == 0){
              state.errors.push("Kein gültiger Aufruf");
	  } else {
              state.errors = [];
	  }  
        commit("fillCatalog", res);
        state.num = 0;
        state.query = state.catalog[state.num];
      } catch (err) {
        state.catalog = [];
        console.error("Fehler beim Laden der Fragen:", err);
        return err;
      }
      state.fetching = false;
    },
    async getcat({ commit, state }) {
        // get list of catalogs	  
	state.fetching = true;
	const response = await fetch(base_url+ "/stats/queries-count",
				     { crossdomain: true ,
				       refererPolicy: 'no-referrer-when-downgrade'});
	try {
	    const result = await response.json();
	    const res = result["qrycounts"] ;
	    commit("setCatsInfos", res);
	    return res
	} catch (err) {
	    console.error("Fehler beim Laden der Antwortliste:", err);
	    return [];
	}
	state.fetching = false;
    },
    async getuserid({ commit, state }) {
      /* List of categories */
      state.fetching = true;
	const response = await fetch(base_url+"/uid",
				     { crossdomain: true ,
				       refererPolicy: 'no-referrer-when-downgrade'});
      try {
        const result = await response.json();
	  const res = result["nuid"]
	  commit("setUserID", {"uid": res});
      } catch (err) {
        console.error("Abruf neuer ID nicht möglich:", err);
        return err;
      }
      state.fetching = false;
    },

      async sendAnswers({ dispatch, commit, state }) {
	  const url = base_url+"/saveanswer";
	  const requestOptions = {
	      method: 'POST',
	      url: url,
	      userid: state.userid,
	      cat: state.cat,
	      group: state.group,
	      answers: state.answers
	  };
	  axios.post(url, requestOptions)
	      .then(function (response) {
		  //console.log(response)
	      });
	  this.dispatch('getCalendar', state);
	  this.commit('setRegistered', true);	
      },
      async setGroup({ dispatch, state}) {
	const url = base_url+"/setgroup";
	const requestOptions = {
	    method: 'POST',
	    url: url,
	    userid: state.userid,
	    group: state.group
	};

	axios.post(url, requestOptions)
	    .then(function (response) {
		//console.log(response)
	    });
      },
      
      async getCorrectAnswers({ dispatch, commit }, state) {
	const url = base_url+"/answersucat";
	const requestOptions = {
	    method: 'POST',
	    url: url,
	    cat: state.cat,
	    contentType: "application/json; charset=utf-8",
	    dataType: "json"
	};
	axios.post(url, requestOptions)
	    .then(function (response) {
		const result = response
		commit("fillCorrectAnswers", JSON.parse(JSON.stringify(result.data.answerlist)));
	    });
    },

    async getCalendar({ commit, state }) {
      /* Querylist for on catalog */
	state.fetching = true;
	const response = await fetch(base_url+ "/cal/"+ state.userid,
				     { crossdomain: true });
	try {
            const result = await response.json();
	    const res = result["calendar"] ; // JSON.parse(JSON.stringify(result["calendar"]));
            commit("setCalendar", res);	    	    
	} catch (err) {
            console.error("Fehler beim Laden des Kalenders:", err);
            return [];
	}
	state.fetching = false;
    },
      async getAllAnswers({ dispatch, commit, state }) {
      /* Answerlist list for one user */
	state.fetching = true;
	  const response = await fetch(base_url+ "/allanswers/"+ state.userid,
				       { crossdomain: true ,
				         refererPolicy: 'no-referrer-when-downgrade'});
	try {
            const res = await response.json();
            commit("setAllAnswers", res);
	} catch (err) {
            console.error("Fehler beim Laden der Antwortliste:", err);
            return [];
	}
	state.fetching = false;
    },
      async getStatsUserMonth({ commit, state }) {
	  /* Statistics:User */
	state.fetching = true;
	  const response = await fetch(base_url + "/stats/count-per-month",
				       { crossdomain: true,
				         refererPolicy: 'no-referrer-when-downgrade'});
	try {
            const result = await response.json();
	    const res = result["statistik"] ;
            commit("setUserStats", res);	    	    
	} catch (err) {
            console.error("Fehler beim Laden der Statistik:", err);
            return [];
	}
	state.fetching = false;
      },
      async getGroupResults({ dispatch, commit, state }) {
	const url = base_url+"/gstats/answers";
	const requestOptions = {
	    method: 'POST',
	    url: url,
	    orga: state.group,
	    cat: state.cat
	};

	axios.post(url, requestOptions)
	    .then(function (response) {
		const result = response;
		commit("setAllAnswers", result.data.statistik);
	    });
      },
      async getMetaQueries({ dispatch, commit, state }) {
	  state.fetching = true;
	  const response = await fetch(base_url + "/stats/meta/queries",
				       { crossdomain: true ,
				         refererPolicy: 'no-referrer-when-downgrade'});
	  try {
            const result = await response.json();
	    const res = result["Fragen"] ;
            commit("setInfo", res);
	} catch (err) {
            console.error("Fehler beim Laden der Statistik:", err);
            return [];
	}
	state.fetching = false;
      },
      async getMetaAnswers({ dispatch, commit, state }) {
	  const url = base_url+"/stats/meta/answers";
	  const requestOptions = {
	      method: 'POST',
	      url: url,
	      meta_id: state.num
	  };
	  
	  axios.post(url, requestOptions)
	      .then(function (response) {
		  const result = response; 
		  commit("setMeta", JSON.parse(JSON.stringify(result)));
	      });
      },
  },	      
});
