import { createApp } from "vue"
import { userstore } from "./stores/userstore.js"
import App from "./App.vue"
import router from "./router"
import './index.css'

const app = createApp(App);
app.use(userstore);
app.use(router);
app.mount("#app");
