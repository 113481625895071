/*eslint no-unused-vars: 0 */

import { createRouter, createWebHistory } from "vue-router";
const FragenStatistik = () => import("@/views/statistics/FragenStatistik.vue");
const SeSecResults = () => import("@/views/SeSecResults.vue");
const NotFound = () => import("@/views/NotFound.vue");
const UserStatsOneTest = () => import("@/views/statistics/UserStatsOneTest.vue");
const UserStatsAllTests = () => import( "@/views/statistics/UserStatsAllTests.vue");
const Teilnehmerzahlen = () => import('@/views/statistics/Teilnehmerzahlen.vue')
const MetaStatistics = () => import( "@/views/statistics/MetaStats.vue");
const GroupStatistics = () => import('@/views/statistics/GroupStats.vue')
const Calendar = () => import("@/components/calendar/SeSecCalendar.vue")
const router = createRouter({

history: createWebHistory(import.meta.env.BASE_URL),
    
    routes: [
	{
	    path: "/",
	    name: "home",
	    component: () => import("@/views/AboutView.vue"),
	    children: [
		{ path: "/", component: () => import("@/views/AboutView.vue") },
		{ path: "register", component: () => import("@/views/RegisterView.vue") },
 		{ path: "calendar", component: Calendar },  
		{ path: "about", component: () => import("@/views/AboutView.vue") },
		{ path: "impress", component: () => import("@/views/ImpressView.vue") },
		{
		    path: "datenschutz",
		    component: () => import("@/views/DatenschutzView.vue"),
		},
		{ path: "faq", component: () => import("@/views/FaqView.vue") },
		{ path: "sesec", component: () => import("@/views/SeSecSetup.vue") },
		{ path: "quiz", component: () => import("@/views/SeSecQuiz.vue") },
		{ path: 'user/:id', component: () => import("@/views/RegisterView.vue") },
	    ],
	},
	{
	    path: "/statistics",
	    name: "statistics",
	    component:  SeSecResults,
	    children: [
		{ path: "testsummary", component: UserStatsOneTest },
		{ path: "allanswers", component: UserStatsAllTests },
		{ path: "fragen", component: FragenStatistik },
		{ path: "teilnehmer", component: Teilnehmerzahlen },
		{ path: "meta", component: MetaStatistics },
		{ path: "group", component: GroupStatistics },
	    ],
	},
	{
	    path: "/:catchAll(.*)",
	    name: "NotFound",
	    component: () => import("@/views/NotFound.vue"),
	}
    ]
});

export default router;
