<template>
<div>
  <section class="shadow-2xl mb-4">
    <div>
	<nav class="container
		 px-6
		 py-8
		 mx-auto
		 md:flex md:justify-between md:items-center">
          <div class="flex items-center justify-between">
            <router-link
              to="/"
              class="border-none
	             focus:border-none
		     active:border-none
		     visited:border-none">
		<img src="@/assets/logo-alarm.png"
		     alt="Logo"
		     class="h-10 border-none"/>

            </router-link>
            <!-- Mobile menu button -->
            <div @click="showMenu = !showMenu" class="flex md:hidden">
              <button
		type="button"
		class="
                       text-gray-800
                       hover:text-gray-400
                       focus:outline-none focus:text-gray-400
		       "
		>
		<svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                  <path
                    fill-rule="evenodd"
		    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0
		       2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z">
		  </path>
		</svg>
              </button>
            </div>
          </div>
	  
	  
          <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
          <ul
            :class="showMenu ? 'flex' : 'hidden'"
            class="
		   flex-col
		   mt-8
		   space-y-4
		   md:flex
		   md:space-y-0
		   md:flex-row
		   md:items-center
		   md:space-x-10
		   md:mt-0
		   md:mr-20
		   "
            >
	    <!-- Menu Items -->
	      <router-link to="/register"
			   class="uppercase hover:text-alarmblue
				  underline decoration-transparent
				   transition duration-300
				   ease-in-out
				   hover:decoration-inherit">Meine Daten</router-link>
              <router-link  to="/sesec"
			    class="uppercase tracking-widest
				   hover:text-alarmblue
				   underline decoration-transparent
				   transition duration-300
				   ease-in-out
				   hover:decoration-inherit">Tests</router-link>
              <router-link  to="/calendar"
			    class="uppercase tracking-widest
				   hover:text-alarmblue
				   underline decoration-transparent
				   transition duration-300
				   ease-in-out
				   hover:decoration-inherit">Kalender</router-link>
	      
              <!-- // Dropdown -->
	      <div class="relative inline-block text-left">
		<div>
		  <button type="button"
			  class="inline-flex
				 uppercase tracking-widest
				 hover:text-alarmblue
				 underline decoration-transparent
				 transition duration-300
				 ease-in-out
				 hover:decoration-inherit
				 bg-white px-3 py-2 text-gray-900
				 hover:bg-gray-50"
			  id="menu-button"
			  v-on:click="subMenuVisible = !subMenuVisible"
			  aria-expanded="true"
			  aria-haspopup="true">
		    Statistik
		    <svg class="-mr-1 h-5 w-5 text-gray-400"
			 viewBox="0 0 20 20" fill="currentColor"
			 aria-hidden="true">
		      <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10
						   11.168l3.71-3.938a.75.75 0 111.08
						   1.04l-4.25 4.5a.75.75 0 01-1.08
						   0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
		    </svg>
		  </button>
		</div>
		
		<div v-show='subMenuVisible' 
		     class="absolute left-0 z-10 mt-2 w-56 origin-top-right
			    rounded-md bg-white shadow-lg ring-1 ring-black
			    ring-opacity-5 focus:outline-none"
		     role="menu"
		     aria-orientation="vertical"
		     aria-labelledby="menu-button"
		     tabindex="-1">
		  <div class="py-1" role="none">
		    <ul>
		      <li>
			<router-link  to="/statistics/testsummary"
				      v-on:click="subMenuVisible = !subMenuVisible"
				      class="tracking-widest block px-4 py-2
					     hover:bg-gray-100 z-30
					     underline decoration-transparent
					     transition duration-300
					     ease-in-out
					     hover:decoration-inherit">
			  Aktueller Test
			</router-link>
		      </li>
		      <li>
			<router-link  to="/statistics/allanswers"
				      v-on:click="subMenuVisible = !subMenuVisible"
				      class="tracking-widest block px-4 py-2
					     hover:bg-gray-100 z-30
					     underline decoration-transparent
					     transition duration-300
					     ease-in-out
					     hover:decoration-inherit">
			  Alle Antworten
			</router-link>
		      </li>
		      <li>
			<router-link  to="/statistics/fragen"
				      v-on:click="subMenuVisible = !subMenuVisible"
				      class="tracking-widest block px-4 py-2
					     hover:bg-gray-100 z-30
					     underline decoration-transparent
					     transition duration-300
					     ease-in-out
					     hover:decoration-inherit">
			  Übersicht: Fragen
			</router-link>
		      </li>
		      <li>
			<router-link  to="/statistics/teilnehmer"
				      v-on:click="subMenuVisible = !subMenuVisible"
				      class="tracking-widest block px-4 py-2
					     hover:bg-gray-100 z-30
					     underline decoration-transparent
					     transition duration-300
					     ease-in-out
					     hover:decoration-inherit">
			  Teilnehmer
			</router-link>
			
			<router-link  to="/statistics/group"
				      v-on:click="subMenuVisible = !subMenuVisible"
				      class="tracking-widest block px-4 py-2
					     hover:bg-gray-100 z-30
					     underline decoration-transparent
					     transition duration-300
					     ease-in-out
					     hover:decoration-inherit">
			  Gruppenvergleich
			</router-link>
			<router-link v-if="metafinished"
				     v-on:click="subMenuVisible = !subMenuVisible"
				     to="/statistics/meta"
				     class="tracking-widest block px-4 py-2
					    hover:bg-gray-100 z-30
					    underline decoration-transparent
					    transition duration-300
					    ease-in-out
					    hover:decoration-inherit">
			  Metadaten
			</router-link>
		      </li>
		    </ul>
		  </div>
		</div>
	      </div>

	  </ul>
	</nav>
    </div>
  </section>
  <!--Image-->
    <div v-if="currentRoutePath ===  '/'">
      <img src="/images/sesec-title.webp"
	   alt="SeSeC-Titelbild"
           class="	"
           />
    </div>
  <!-- -Inhalt passend zur Route -->
  <section id="hero">
    <!-- Container For Image & Content -->
      <!-- Content -->
      <router-view id="panels">
	<router-view />
      </router-view>
  </section>
  
    <footer class="py-2 text-sm lg:py-6 bg-alarmblue">
    <!-- Footer Flex Container -->
    <div class="container flex flex-col items-center justify-between
		mx-auto space-y-10 px-10 md:flex-row md:space-y-0" >
      <!-- Logo/Menu Container -->
      <div class="flex flex-col text-sm items-center justify-between space-y-1
		  lg:text-lg font-light md:flex-row md:space-y-0 md:space-x-14
		  text-white" >
	<router-link to="/faq"
		     class="uppercase
			    transition duration-300
			    ease-in-out
			    hover:decoration-inherit"> 
          <dfn>
	    <abbr class="no-underline not-italic hover:underline"
	    title="Frequently Ask Questions (dt. häuffig gestellte Fragen">
	      FAQ — SeSeC
	    </abbr>
          </dfn>
        </router-link>
	<router-link to="/impress"
		     class="uppercase
			    underline decoration-transparent
			    transition duration-300
			    ease-in-out
			    hover:decoration-inherit">Impressum</router-link>
        <router-link to="/datenschutz"
		     class="uppercase
			    underline decoration-transparent
			    transition duration-300
			    ease-in-out
			    hover:decoration-inherit">Datenschutzerklärung</router-link>
      </div>
    </div>
  </footer>
  <div class="container flex flex-col items-center justify-center
	      mx-auto  px-4 md:flex-row md:space-y-0">
    <img src="@/assets/images/footer-logos-01.webp" alt="Footer 01" class="h-24 md:h-48" />
    <img src="@/assets/images/footer-logos-02.webp" alt="Footer 02" class="h-24 md:h-48" />
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
	visible: false,
	currentRoutePath: "/",
	showMenu: false,
	subMenuVisible: false
    };
  },
  computed: {
    ...mapGetters({
      metafinished: "metafinished"
    }),
  },
  
  methods: {
    currentRoute() {
      this.currentRoutPathe = this.$route.path;
    },
  },
  watch: {
    ["$route.path"](route) {
	this.currentRoutePath = route;
	this.showMenu = false;
    },
  },
};
</script>
